
import React from "react"
import Helmet from 'react-helmet';
import { Layout } from "../components/Layout/Layout";

const NotFoundPage = () => (
  <Layout>
    <Helmet>
      <title>404 Page - Devwares </title>
      <meta name="title" content="404 Page - Devwares " />
      <meta
        name="description"
        content="You have hit a route that does not exist"
      />
      <meta property="og:title" content="404 Page - Devwares " />
      <meta
        property="og:description"
        content="You have hit a route that does not exist"
      />
      <meta property="twitter:title" content="404 Page - Devwares " />
      <meta
        property="twitter:description"
        content="You have hit a route that does not exist"
      />
      <link rel="canonical" href="https://www.devwares.com/404" />
      <meta property="og:url" content="https://www.devwares.com/404" />
      <meta property="og:site_name" content="Devwares" />
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
    <h1>404: Not Found</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
);

export default NotFoundPage